// DiscoverFlixRow.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import FlixCard from '../container/FlixCard.js';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import {
  InfiniteScroller,
  ScrollItem,
} from '../component/InfiniteScroller.jsx';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import HydrationBoundary from '../component/HydrationBoundary.jsx';
import { getIosVersion } from '../resource/getUserAgent.js';
import { freezoneFeed, freezoneType } from '../resource/freezoneConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { ButtonId } from '../resource/mixpanel.js';

import MoreIconSrc from '../../img/ic_arrow_right_white.svg';
import { color } from '../style/variables.js';
import media from '../style/media.js';
import { flixRowAutoPlayWidth } from '../style/variables.js';

const isServer = typeof window === 'undefined';

export class DiscoverFlixRow extends React.PureComponent {
  state = {
    shouldAutoPlay: false,
    isFirstFrame: true,
  };
  nextTicks = [];
  messagesElement = undefined;
  setShouldAutoPlay = debounce(() => {
    this.setState({
      shouldAutoPlay: window.screen.width < flixRowAutoPlayWidth,
    });
  }, 500);
  handleMessagesScroll = debounce(() => {
    const { category, setScrollLeft } = this.props;
    if (this.messagesElement) {
      setScrollLeft({
        scrollLeft: this.messagesElement.scrollLeft,
        category,
      });
    }
  }, 500);
  componentDidMount() {
    const { isFetched } = this.props;
    if (window.__IS_HYDRATED__ || !isFetched) {
      // don't need to hydrate since SSR data is empty.
      // mostly for local dev env.
      window.__IS_HYDRATED__ = 'DiscoverFlixRow';
    }
    this.nextTicks.push(
      setTimeout(() => this.setState({ isFirstFrame: false }))
    );
    this.setShouldAutoPlay();
    window.addEventListener('resize', this.setShouldAutoPlay);
  }
  componentDidUpdate(prevProps) {
    const {
      isAuthed,
      shouldRefresh,
      category,
      fetchFeeds,
      scrollLeft,
      feedUnixTimestamp,
    } = this.props;
    if (
      isAuthed !== prevProps.isAuthed ||
      feedUnixTimestamp !== prevProps.feedUnixTimestamp
    ) {
      this.nextTicks.push(
        setTimeout(() =>
          fetchFeeds({
            page: 1,
            type: category,
            unixTimestamp: feedUnixTimestamp,
          })
        )
      );
    }
    if (shouldRefresh && shouldRefresh !== prevProps.shouldRefresh) {
      fetchFeeds({
        page: 1,
        type: category,
        shouldSkipLocalCache: shouldRefresh,
        unixTimestamp: feedUnixTimestamp,
      });
    }
    if (this.messagesElement && scrollLeft > this.messagesElement.scrollLeft) {
      this.messagesElement.scrollLeft = scrollLeft;
    }
  }
  componentWillUnmount() {
    clearTimeout(this.intersectionTimer);
    this.setShouldAutoPlay.cancel();
    window.removeEventListener('resize', this.setShouldAutoPlay);
    this.nextTicks.forEach(clearTimeout);
    if (!window.__IS_HYDRATED__) {
      window.__IS_HYDRATED__ = 'DiscoverFlixRow';
    }
  }

  handleIntersection = ({ isIntersecting }, unobserve) => {
    const { isFetched, fetchFeeds, category, feedUnixTimestamp } = this.props;
    clearTimeout(this.intersectionTimer);
    if (isIntersecting) {
      this.intersectionTimer = setTimeout(() => {
        unobserve();
        if (!isFetched) {
          fetchFeeds({
            page: 1,
            type: category,
            unixTimestamp: feedUnixTimestamp,
          });
        }
      }, 100); // TODO: remote config
    }
  };

  renderHydrateFlixCard = ({
    messageId,
    category,
    index,
    shouldPlay,
    isIntersecting,
    isProfileCategory,
    shouldShowUsername,
  }) => {
    return (
      <HydrationBoundary
        shouldHydrate={isIntersecting}
        wrapper={<FlixCardWrapper onClick={this.handleCardClick} />}
      >
        <FlixCard
          messageId={messageId}
          shouldPlay={shouldPlay}
          categoryId={category}
          categoryIndex={index}
          itemIndexInCategory={index}
          shouldTrackEvent
          shouldShowTooltip
          shouldShowAvatar={!isProfileCategory}
          shouldShowUsername={shouldShowUsername || !isProfileCategory}
          shouldUseLazyImage={false}
          shouldAnimateLoader={isIntersecting}
          isIntersecting={isIntersecting}
        />
      </HydrationBoundary>
    );
  };

  renderFlixCard = ({
    messageId,
    category,
    index,
    shouldPlay,
    isIntersecting,
    isProfileCategory,
    shouldShowUsername,
  }) => {
    return (
      <FlixCardWrapper onClick={this.handleCardClick}>
        <FlixCard
          messageId={messageId}
          shouldPlay={shouldPlay}
          categoryId={category}
          categoryIndex={index}
          itemIndexInCategory={index}
          shouldTrackEvent
          shouldShowTooltip
          shouldShowAvatar={!isProfileCategory}
          shouldShowUsername={shouldShowUsername || !isProfileCategory}
          shouldUseLazyImage={false}
          shouldAnimateLoader={isIntersecting}
          isIntersecting={isIntersecting}
        />
      </FlixCardWrapper>
    );
  };

  renderPlaceholders = () => {
    return (
      <MessagesWrapper>
        <Messages>
          {Array(10)
            .fill()
            .map((elem, index) => (
              <FlixCardWrapper key={index}>
                <FlixCard messageId={null} shouldShowAvatar />
              </FlixCardWrapper>
            ))}
        </Messages>
      </MessagesWrapper>
    );
  };

  renderListContent = () => {
    const { isFirstFrame, shouldAutoPlay } = this.state;
    const {
      messageIds,
      nextPage,
      isFetching,
      fetchFeeds,
      category,
      shouldPlayFeedId,
      isProfileCategory,
      feedUnixTimestamp,
      shouldShowUsername,
    } = this.props;
    const shouldHydrate = isServer || !window.__IS_HYDRATED__;
    const renderFlixCard = shouldHydrate
      ? this.renderHydrateFlixCard
      : this.renderFlixCard;
    return (
      <MessageScroller
        onScroll={this.handleMessagesScroll}
        ref={el => (this.messagesElement = el)}
      >
        <WithIntersectionObserver
          shouldKeepObserve={true}
          onChange={this.handleIntersection}
        >
          {() => (
            <InfiniteScroller
              axis="x"
              useWindow={false}
              pageStart={1}
              hasMore={nextPage !== null}
              loadMore={() =>
                fetchFeeds({
                  page: nextPage,
                  type: category,
                  unixTimestamp: feedUnixTimestamp,
                })
              }
              isFetching={isFetching}
              fetchNextPage={
                nextPage
                  ? () =>
                      fetchFeeds({
                        page: nextPage,
                        type: category,
                        unixTimestamp: feedUnixTimestamp,
                      })
                  : null
              }
              threshold={100}
            >
              <MessagesWrapper>
                <Messages>
                  <ScrollItem
                    loader={
                      <FlixCardWrapper>
                        <FlixCard shouldShowAvatar />
                      </FlixCardWrapper>
                    }
                  >
                    {messageIds.length
                      ? messageIds.map((messageId, index) => (
                          <WithIntersectionObserver
                            key={messageId}
                            shouldKeepObserve
                            threshold={1}
                          >
                            {({ intersectionRatio }) => {
                              return (
                                <WithIntersectionObserver threshold={0}>
                                  {({ isIntersecting }) => {
                                    const shouldPlay =
                                      shouldAutoPlay &&
                                      intersectionRatio >= 1 &&
                                      shouldPlayFeedId === category;
                                    return renderFlixCard({
                                      messageId,
                                      category,
                                      index,
                                      shouldPlay,
                                      isIntersecting,
                                      isFirstFrame,
                                      isProfileCategory,
                                      shouldShowUsername,
                                    });
                                  }}
                                </WithIntersectionObserver>
                              );
                            }}
                          </WithIntersectionObserver>
                        ))
                      : Array(10)
                          .fill()
                          .map((elem, index) => (
                            <FlixCardWrapper key={index}>
                              <FlixCard messageId={null} shouldShowAvatar />
                            </FlixCardWrapper>
                          ))}
                  </ScrollItem>
                </Messages>
              </MessagesWrapper>
            </InfiniteScroller>
          )}
        </WithIntersectionObserver>
      </MessageScroller>
    );
  };
  render() {
    const {
      t,
      isFetched,
      shouldShowMoreLink,
      shouldShowTotalCount,
      totalCount,
      isProfileCategory,
      isTitleClickable,
      titleI18nId,
      titleLink,
      index,
      category,
      messageIds,
    } = this.props;
    if (messageIds.length === 0 && isFetched) {
      return null;
    }
    return (
      <StyledDiscoverFlixRow>
        <Header>
          <HeaderLeft>
            <Title
              to={titleLink}
              as={isTitleClickable ? Link : undefined}
              replace={isProfileCategory}
            >
              {t(titleI18nId, { ns: TranslationNamespace.FEED })}
              {shouldShowTotalCount && (
                <TotalCount>({totalCount || 0})</TotalCount>
              )}
            </Title>
          </HeaderLeft>
          <HeaderRight>
            {shouldShowMoreLink && (
              <CategoryMoreButton
                to={
                  category === freezoneFeed.AMATEUR
                    ? `/freezone/${freezoneType.AMATEUR}`
                    : `/flix-categories/${category}`
                }
                data-element_id={ButtonId.All.ButtonMore}
                data-tracking_payload={{
                  'discover.index': index,
                  'discover.category': category,
                }}
                title={t(titleI18nId, {
                  ns: TranslationNamespace.FEED,
                })}
              >
                <MoreButtonText>
                  {t('more', { ns: TranslationNamespace.GENERAL })}
                </MoreButtonText>
                <MoreButtonIcon
                  src={MoreIconSrc}
                  alt={t('more', { ns: TranslationNamespace.GENERAL })}
                />
              </CategoryMoreButton>
            )}
          </HeaderRight>
        </Header>
        {this.renderListContent()}
      </StyledDiscoverFlixRow>
    );
  }
}

DiscoverFlixRow.propTypes = {
  t: PropTypes.func.isRequired,
  titleI18nId: PropTypes.string,
  titleLink: PropTypes.string,
  category: PropTypes.string,
  shouldPlayFeedId: PropTypes.string,
  fetchFeeds: PropTypes.func,
  isAuthed: PropTypes.bool,
  isFetched: PropTypes.bool,
  isFetching: PropTypes.bool,
  shouldRefresh: PropTypes.bool,
  shouldShowMoreLink: PropTypes.bool,
  shouldShowTotalCount: PropTypes.bool,
  shouldShowUsername: PropTypes.bool,
  isProfileCategory: PropTypes.bool,
  isTitleClickable: PropTypes.bool,
  messageIds: PropTypes.array,
  index: PropTypes.number,
  nextPage: PropTypes.number,
  totalCount: PropTypes.number,
  setScrollLeft: PropTypes.func,
  scrollLeft: PropTypes.number,
  feedUnixTimestamp: PropTypes.number,
};

DiscoverFlixRow.defaultProps = {
  isAuthed: false,
  isFetching: false,
  shouldRefresh: false,
  shouldShowMoreLink: true,
  shouldShowTotalCount: false,
  shouldShowUsername: false,
  isProfileCategory: false,
  isTitleClickable: false,
  isFetched: false,
  titleI18nId: '',
  titleLink: '',
  category: '',
  shouldPlayFeedId: '',
  fetchFeeds: () => null,
  messageIds: [],
  index: null,
  nextPage: null,
  totalCount: 0,
  setScrollLeft: () => null,
  scrollLeft: 0,
  feedUnixTimestamp: 0,
};

const StyledDiscoverFlixRow = styled.article`
  background-color: ${color.black};
  min-height: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  padding: 16px 16px 0px;
  justify-content: space-between;
  align-items: center;
  ${media.mobile`
    padding: 0px 16px;
  `};
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: flex-start;
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: max-content;
  margin-left: 8px;
`;

const CategoryMoreButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.white};
`;

const MoreButtonText = styled.span`
  margin-right: 4px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  ${media.mobile`
    font-size: 12px;
  `}
`;

const MoreButtonIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  flex: 1;
  display: flex;
  align-items: center;
  text-align: justify;
  line-height: 1.5;
  ${media.mobile`
    font-size: 18px;
  `}
`;

const TotalCount = styled.span`
  padding-left: 8px;
  color: var(--font-color-primary-on-dark, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.014px;
  ${media.mobile`
    font-size: 12px;
    letter-spacing: 0.012px;
  `}
`;

// On iOS 13, scrolling momentum is applied to elements with scrollbar automatically,
// and "-webkit-overflow-scrolling: touch;" causes rendering bug, so the attribute is not necessary.
// On iOS 12 and below, "-webkit-overflow-scrolling: touch;" is necessary for momentum scrolling.
const MessageScroller = styled.div`
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  ${getIosVersion().major < 13 ? '-webkit-overflow-scrolling: touch;' : ''}

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const MessagesWrapper = styled.div`
  display: inline-block;
`;

const Messages = styled.div`
  padding: 12px 16px 0px;
  display: flex;
`;

const FlixCardWrapper = styled.div`
  flex: none;
  margin-right: 8px;
  border-radius: 4px;
  width: 260px;
  :last-child {
    margin-right: 0;
  }
`;

export default withTranslation()(DiscoverFlixRow);
